import type { CSSObject } from '@emotion/react';
import type { SxProps, Theme } from '@mui/material/styles';

import memoize from 'lodash/memoize';

import { neutralColors, neutralV2Colors } from '@/theme/color-tokens';

interface AppMixins {
  sx: SxProps<Theme>;
  style?: CSSObject;
}

export const CIGRO_SCROLL_BAR_MIXINS: AppMixins = {
  sx: {
    ['&::-webkit-scrollbar']: {
      width: '4px',
      height: '4px', // NOTE: for horizontal scrollbar
      borderRadius: ' 8px',
      backgroundColor: 'neutralV2.7',
    },
    ['&::-webkit-scrollbar-thumb']: {
      borderRadius: '4px',
      backgroundColor: 'neutralV2.4',
    },
  },
  style: {
    ['::-webkit-scrollbar']: {
      width: '4px',
      height: '4px',
      borderRadius: ' 8px',
      backgroundColor: neutralColors[6],
    },
    // ['::-webkit-scrollbar-track']:{
    //   width: '4px',
    //   height: '4px',
    //   borderRadius: ' 8px',
    //   backgroundColor: neutralColors[6],
    // },
    ['::-webkit-scrollbar-thumb']: {
      borderRadius: '4px',
      backgroundColor: neutralColors[4],
    },
  },
};

export const CIGRO_SCROLL_BAR_V1_MIXINS: AppMixins = {
  sx: {
    ['&::-webkit-scrollbar']: {
      bgcolor: 'neutralV2.7',
      borderTopRightRadius: 'inherit', // NOTE: vertical
      // borderBottomLeftRadius: 'inherit', // NOTE: horizontal
      //
      borderBottomRightRadius: 'inherit',
    },
    ['&::-webkit-scrollbar-thumb']: {
      bgcolor: 'neutralV2.5',
      backgroundClip: 'padding-box',
      border: '4px solid rgba(0, 0, 0, 0)',
      borderRadius: '8px',
    },
  },
};

export const CIGRO_SCROLL_BAR_V2_MIXINS: AppMixins = {
  sx: {
    ['&::-webkit-scrollbar']: {
      width: '8px',
      height: '8px', // NOTE: for horizontal scrollbar
      borderRadius: ' 8px',
      backgroundColor: (theme) => theme.palette.neutral[6],
    },
    ['&::-webkit-scrollbar-track']: {
      backgroundColor: (theme) => theme.palette.neutral[6],
    },
    ['&::-webkit-scrollbar-thumb']: {
      borderRadius: '4px',
      backgroundColor: (theme) => theme.palette.neutral[4],
    },
    ['&::-webkit-scrollbar-corner']: {
      borderBottomRightRadius: '10px',
      backgroundColor: (theme) => theme.palette.neutral[6],
    },
  },
};

export const CIGRO_AVATAR_MIXINS: AppMixins = {
  sx: {
    height: '34px',
    width: '34px',
    borderRadius: '10px',
    borderColor: 'neutral.5',
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: (theme: Theme) => theme.typography.button.fontSize,
    fontWeight: (theme: Theme) => theme.typography.button.fontWeight,
    lineHeight: (theme: Theme) => theme.typography.button.lineHeight,
    backgroundColor: (theme: Theme) => theme.palette.point.mediumTurquoise100,
    ':focus': {
      outline: 'none',
      boxShadow: (theme: Theme) => `0 0 0 1px ${theme.palette.primary[30]}`,
    },
    ':active': {
      outline: 'none',
      boxShadow: (theme: Theme) => `0 0 0 1px ${theme.palette.primary[30]}`,
    },
  },
};

const SAFE_LOOP_COUNT = 100000;

export const CIGRO_ANIMATION_ROTATE_COUNTER_CLOCKWISE: {
  sx: SxProps<Theme>;
  getSx: (s?: number) => SxProps<Theme>;
} = {
  sx: {
    '@keyframes counter-clockwise': {
      '0%': {
        WebkitAnimation: 'rotateZ(360deg) translateZ(0)',
        transform: 'rotateZ(360deg) translateZ(0)',
      },
      '100%': {
        WebkitAnimation: 'rotateZ(0deg) translateZ(0)',
        transform: 'rotateZ(0deg) translateZ(0)',
      },
    },
    WebkitAnimation: 'counter-clockwise 1.5s linear infinite',
    animation: 'counter-clockwise 1.5s linear infinite',
  },
  getSx: (s = 1) => ({
    transform: `rotate(-${s * SAFE_LOOP_COUNT}turn)`,
    transitionDuration: `${s * SAFE_LOOP_COUNT}s`,
    transitionTimingFunction: 'linear',
  }),
};

export const CIGRO_ANIMATION_WAVING = {
  sx: {
    '@keyframes oscillate': {
      from: {
        transform: 'translateY(0px)',
        color: 'neutralV2.8',
      },
      to: {
        transform: 'translateY(8px)',
        color: 'primary.100',
      },
    },
  },
  useSx: {
    animation: ' oscillate 500ms infinite',
    /* Ping-pong between “from” and “to”: */
    animationDirection: 'alternate',
    /* Use a symmetrical timing function: */
    animationTimingFunction: 'ease-in-out',
    /* Avoid an initial flicker: */
    animationFillMode: 'backwards',
  },
};
export const ELLIPSIS_CSS = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const getScrollbarSx = memoize((scrollbarW: number, gap: number) => ({
  '--cg-scrollbar-size': scrollbarW + 2 * gap + 'px',
  ['&::-webkit-scrollbar']: {
    width: 'var(--cg-scrollbar-size)',
    height: 'var(--cg-scrollbar-size)',
  },
  '&::-webkit-scrollbar-track': {
    bgcolor: 'transparent',
  },
  ['&::-webkit-scrollbar-thumb']: {
    bgcolor: 'neutralV2.4',
    borderRadius: 'var(--cg-scrollbar-size)',
    backgroundClip: 'padding-box',
    border: `${gap}px solid transparent`,
  },
}));

export const CIGRO_SCROLL_BAR_4PX_MIXINS = getScrollbarSx(4, 4);

const getScrollbarCssProps = memoize((scrollbarW: number, gap: number) => ({
  ['::-webkit-scrollbar']: {
    display: 'block',
    width: `${scrollbarW + gap}px`,
    height: `${scrollbarW + gap}px`,
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: neutralV2Colors[8],
    borderRadius: '10px',
  },
  ['::-webkit-scrollbar-thumb']: {
    backgroundColor: neutralV2Colors[4],
    backgroundClip: 'padding-box',
    borderRight: `${gap}px solid rgba(0, 0, 0, 0)`,
    borderTop: `${gap}px solid rgba(0, 0, 0, 0)`,
  },
}));

export const CIGRO_SCROLL_BAR_4PX_MIXINS_CSS_PROPS = getScrollbarCssProps(4, 4);

export const CIGRO_SCROLL_BAR_HIDDEN = {
  ['&::-webkit-scrollbar']: {
    display: 'hidden',
  },
};
