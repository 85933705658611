'use client';

import type { SxProps, Theme } from '@mui/material/styles';

import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import {
  Input as InputUnstyled,
  inputClasses as inputUnstyledClasses,
  type InputProps as InputUnstyledProps,
} from '@mui/base/Input';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CLASSNAME_ICON_CIGRO } from '@/constants';
import { useDebounceHandler } from '@/hooks/use-debounce-handler';
import AppIcon from '@components/Icon';

import { AppButtonV2 } from './Button';

const StyledSearchInput = styled(InputUnstyled, {
  shouldForwardProp: (propName) => propName !== 'inputRef' && propName !== 'sx',
})(({ theme }) => ({
  width: '100%',
  height: '32px',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: theme.palette.neutral[7],
  paddingLeft: '10px',
  paddingRight: '5px',
  border: `1px solid ${theme.palette.neutral[4]}`,
  color: theme.palette.neutral[3],

  [`& .${inputUnstyledClasses.input}`]: {
    border: 'none',
    display: 'block',
    height: '100%',
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '5px',
    backgroundColor: 'inherit',
    ...theme.typography.body2,
    color: theme.palette.text[0],
    '&::placeholder': {
      // ...theme.typography.body2,
      color: theme.palette.text[2],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  [`&.${inputUnstyledClasses.focused}`]: {
    borderColor: theme.palette.neutralV2[0],
  },
  '&:hover': {
    borderColor: theme.palette.neutralV2[0],
    color: theme.palette.neutral[1],
    [`& .${inputUnstyledClasses.input},& .${CLASSNAME_ICON_CIGRO}`]: {
      color: theme.palette.neutral[1],
    },
  },
  [`&.${inputUnstyledClasses.disabled}`]: {
    backgroundColor: theme.palette.neutral[4],
    color: theme.palette.text[2],
    [`& .${inputUnstyledClasses.input},& .${CLASSNAME_ICON_CIGRO}`]: {
      backgroundColor: theme.palette.neutral[4],
      color: theme.palette.text[2],
      '&::placeholder': {
        // ...theme.typography.body2,
        color: theme.palette.text[2],
      },
    },
  },
}));

interface IAppSearchInput {
  iconSize?: number;
  onClear?: () => void;
  onClickMagnifyingGlass?: () => void;
  onDebouncedCallbackChange: (searchValue: string) => void;
}

export const ClearButton = ({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => (
  <AppButtonV2
    variant="text"
    color="tertiary"
    onClick={onClick}
    sx={{ bgcolor: 'inherit', borderRadius: '4px', padding: '5px' }}
  >
    <AppIcon
      name="close"
      size={10}
      color={(theme) => theme.palette.neutral[3]}
    />
  </AppButtonV2>
);

export const SearchButton = ({
  onClick,
  sx,
  iconSize = 18,
}: {
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  iconSize?: number;
}) => (
  <AppButtonV2
    variant="text"
    color="tertiary"
    onClick={onClick}
    sx={[
      { borderRadius: '4px', padding: '4px' },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    //
  >
    <AppIcon name="ic_search_18" color={'neutralV2.3'} size={iconSize} />
  </AppButtonV2>
);

const AppSearchInput = forwardRef<
  HTMLInputElement,
  IAppSearchInput & InputUnstyledProps & { sx?: SxProps<Theme> }
>(
  (
    {
      onDebouncedCallbackChange,
      onClear,
      onClickMagnifyingGlass,
      endAdornment,
      defaultValue,
      iconSize = 18,
      ...inputProps
    },
    ref,
  ) => {
    const defaultSearchValue =
      typeof defaultValue === 'string' ? defaultValue : '';
    const [searchValue, setSearchValue] = useState(defaultSearchValue);

    const debouncedOnChange = useDebounceHandler(() =>
      onDebouncedCallbackChange(searchValue),
    );
    const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
      const searchText = e.target.value;

      setSearchValue(searchText);
      debouncedOnChange();
    };

    useEffect(() => {
      setSearchValue(defaultSearchValue);
    }, [defaultSearchValue]);

    return (
      <StyledSearchInput
        {...inputProps}
        value={searchValue}
        onChange={handleSearchValue}
        slotProps={{
          input: {
            ref,
          },
        }}
        endAdornment={
          searchValue ? (
            <ClearButton
              onClick={() => {
                setSearchValue('');
                if (onClear) onClear();
              }}
            />
          ) : (
            <SearchButton iconSize={14} onClick={onClickMagnifyingGlass} />
          )
        }
      />
    );
  },
);

// @ts-expect-error
AppSearchInput.displayName = 'AppSearchInput';

export default AppSearchInput;
