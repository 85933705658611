'use client';

import React, { useState } from 'react';

import {
  Option as OptionUnstyled,
  optionClasses as optionUnstyledClasses,
} from '@mui/base/Option';
import { Popper as PopperUnstyled } from '@mui/base/Popper';
import {
  Select as SelectUnstyled,
  selectClasses as selectUnstyledClasses,
  SelectOwnProps as SelectUnstyledOwnProps,
  SelectProps as SelectUnstyledProps,
  SelectRootSlotProps as SelectUnstyledRootSlotProps,
} from '@mui/base/Select';
import Box from '@mui/material/Box';
import { css, styled, TypographyVariant, useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ChevronDownIcon from '@/assets/icons/ChevronDown';
import { ELLIPSIS_CSS } from '@/constants/mixins';

const MIN_HEIGHT_PX = 32;
const MIN_WIDTH_PX = 0;
const TEXT_LIMIT_ONE_LINE_MIXIN = css`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Button = React.forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
>(
  props: SelectUnstyledRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, ...other } = props;
  const theme = useTheme();

  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <Box>
        {!other['aria-expanded'] ? (
          <ChevronDownIcon
            color={ownerState.disabled ? theme.palette.neutral[4] : undefined}
          />
        ) : (
          <ChevronDownIcon
            sx={{ transform: 'rotate(180deg)' }}
            color={ownerState.disabled ? theme.palette.neutral[4] : undefined}
          />
        )}
      </Box>
    </button>
  );
});

const StyledButton = styled(Button, { shouldForwardProp: () => true })(
  ({ theme }) => `
  font-family: ${theme.typography.body2.fontFamily};
  font-size: ${theme.typography.body2.fontSize};
  line-height: ${theme.typography.body2.lineHeight};
  color: ${theme.palette.text[2]};
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.neutralV2[4]};
  border-radius: ${theme.shape.borderRadius}px;
  box-sizing: border-box;
  padding: 7px;
  min-height: ${MIN_HEIGHT_PX}px;
  min-width: ${MIN_WIDTH_PX}px;
  max-width:100%;
  width: 100%;
  text-align: left;
  position: relative;
  cursor: pointer;
  margin:0;
  &:hover{
    background-color: ${theme.palette.neutralV2[7]};
  }

  ${TEXT_LIMIT_ONE_LINE_MIXIN.styles}
  display:flex;

  &.${selectUnstyledClasses.disabled} {
    cursor: not-allowed;
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 2px solid ${theme.palette.primary[50]};
  }

  &.${selectUnstyledClasses.expanded} {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  &:disabled{
    background:${theme.palette.neutral[6]};
    border: 1px solid ${theme.palette.neutral[5]};
    
    p {
      color:${theme.palette.text[2]};
    }
  }

  & > .MuiSvgIcon-root {    
    position: absolute;
    top: 0;
    right: 13px;
    height: 100%;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: ${theme.shape.borderRadius}px;
  border-bottom-right-radius: ${theme.shape.borderRadius}px;
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize}px;
  min-width: ${MIN_WIDTH_PX}px;
  color: ${theme.palette.text[0]};
  background: ${theme.palette.background[1]};
  box-sizing: border-box;
  padding: 6px;
  margin: -1px 0px 0px 0px;
  width: 100%;
  overflow: auto;
  outline: none;
  border: 1px solid ${theme.palette.neutral[5]};
  border-top-width: 0; 
  
  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${theme.palette.neutral[6]};
  }

  &::-webkit-scrollbar-corner {
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${theme.palette.neutral[6]};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${theme.palette.neutral[4]};
  }
`,
);

export const StyledOption = styled(OptionUnstyled)(
  ({ theme, value }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: ${!!value ? theme.palette.text[0] : theme.palette.text[4]};
  
  ${TEXT_LIMIT_ONE_LINE_MIXIN.styles}

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: ${theme.palette.neutral[6]};
  }

  &.${optionUnstyledClasses.selected} {
    background-color: none;
    color:${theme.palette.text[4]};
    cursor:pointer;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: none;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: none;
  }

  &.${optionUnstyledClasses.disabled} {
    cursor: not-allowed;
    pointer-event: none;
    color: ${theme.palette.text[4]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: none;
  }
  `,
);

//  z-index: 1;
//  width: 100%;
//  inset: 0 0 !important;
const StyledPopper = styled(PopperUnstyled)(
  ({ theme }) => `
    z-index: ${theme.zIndex.modal};
`,
);

const StyledSelect = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean,
>(
  props: SelectUnstyledProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { slots, slotProps } = props;
  const parsedSlots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...slots,
  };

  return (
    <SelectUnstyled
      {...props}
      ref={ref}
      slots={parsedSlots}
      slotProps={slotProps}
    />
  );
});

// @ts-expect-error
StyledSelect.displayName = 'StyledSelect';

interface SelectOption<TValue extends {}> {
  value: TValue;
  label: React.ReactNode;
  disabled?: boolean;
}

export interface AppSelectProps<TValue extends {}, Multiple extends boolean>
  extends SelectUnstyledOwnProps<TValue, Multiple> {
  placeholder?: string;
  options: Array<SelectOption<TValue>>;
  filterOption?: (opt: SelectOption<TValue>) => boolean;
  selectElWidth?: number;
  variant?: TypographyVariant;
  onOptionClick?:
    | ((
        e: React.MouseEvent<HTMLOptionElement>,
        option: SelectOption<TValue>,
      ) => void)
    | undefined;
}

export default function AppSelect<
  TValue extends {} | string,
  Multiple extends boolean = false,
>(props: AppSelectProps<TValue, Multiple>) {
  const {
    options = [],
    // onChange,
    placeholder,
    filterOption = () => true,
    multiple,
    selectElWidth = null,
    slotProps,
    children,
    variant = 'body2',
    onOptionClick,
    ...commonProps
  } = props;

  const [containerWidth, setContainerWidth] = useState(selectElWidth);

  return (
    <Box sx={{ position: 'relative', width: selectElWidth || '100%' }}>
      <StyledSelect<TValue, Multiple>
        multiple={multiple}
        ref={(el) => {
          setContainerWidth(el?.offsetWidth || null);
        }}
        renderValue={(option) =>
          // TODO: handle multi-value case here
          Array.isArray(option) ? null : (
            <Typography
              variant={variant}
              sx={(theme) => ({
                color: Boolean(option?.value)
                  ? theme.palette.text[0]
                  : theme.palette.text[2],
                ...ELLIPSIS_CSS,
                width: '100%',
                WebkitLineClamp: 1,
              })}
            >
              {option && !Array.isArray(option)
                ? !option.value
                  ? placeholder
                  : option.label
                : placeholder}
            </Typography>
          )
        }
        slotProps={{
          ...slotProps,
          listbox: {
            style: {
              width: containerWidth || '100%',
              maxHeight: 320,
            },
            ...slotProps?.listbox,
          },
        }}
        {...commonProps}
        //
      >
        {children ||
          options.filter(filterOption).map((opt, idx) => {
            const optValue = opt.value;
            const optLabel = opt.label;
            const isDisabled = !!opt?.disabled;

            return (
              <Tooltip key={idx} title={optLabel} followCursor>
                <StyledOption
                  value={optValue}
                  disabled={isDisabled}
                  onClick={(e: React.MouseEvent<HTMLOptionElement>) => {
                    onOptionClick?.(e, opt);
                  }}
                  //
                >
                  <Typography variant={variant}>{optLabel}</Typography>
                </StyledOption>
              </Tooltip>
            );
          })}
      </StyledSelect>
    </Box>
  );
}
