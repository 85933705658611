import { ApiError } from '@/api-error';

import { isApiError } from './api-error';

/**
 * Gracefully capture any error in the following format [valid response, API error, Unknown error]
 * @returns [maybe-not-null, null, null] -> No error, safely read the response!
 * @returns [null, not-null, null] -> API error or user error
 * @returns [null, null, not-null] -> Unknown error
 */
export async function to<T>(
  fn: () => Promise<T | null>,
): Promise<[T | null, ApiError | null, unknown]> {
  try {
    const data = await fn();
    return [data, null, null];
  } catch (error) {
    // if (isApiError(error) && error.isUserError()) return [null, error, null];
    if (isApiError(error)) return [null, error, null];
    return [null, null, error];
  }
}

/**
 * Gracefully capture any error in the following format [API error, response | undefined]
 * @param { Promise } promise
 * @return { Promise }
 */
export async function until<T>(
  promise: Promise<T>,
): Promise<[ApiError, undefined] | [null, T]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[ApiError, undefined]>((err: unknown) => {
      if (isApiError(err)) return [err, undefined];
      return [
        new ApiError(
          err,
          {
            detail: 'unknown client side error',
          },
          400,
        ),
        undefined,
      ];
    });
}
